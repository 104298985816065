import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    Button,
    Card,
    Grid,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from "@mui/material";
import { ContentContainer, MUITable, TField } from "components";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";

interface Destination {
    name: String,
    format: String,
    endpoint: String,
    username: String,
    password: String,
}

interface Configuration {
    path: String,
    name: String,
    destinations: Destination[]
}

const TelemetryConfiguration = () => {
    const navigate = useNavigate();
    const { t } = useTranslation("telemetry");

    const [config, setConfig] = useState<Configuration[]>([]);
    const [selConfigIndex, setSelConfigIndex] = useState(-1);

    // TODO: Change type any after redo auth
    const { user: { client: { clnt } } } = useSelector((state: any) => state.user);

    const confoptions: MUIDataTableOptions = {
        download: false,
        print: false,
        responsive: "standard",
        draggableColumns: { enabled: false },
        selectableRows: "none",
        rowsPerPageOptions: [10, 50, 100],
        onRowClick(rowData, rowMeta) {
            setSelConfigIndex(rowMeta.rowIndex);
        },
    };

    const confcolumns = [
        { label: t("Path"), name: "path" },
        { label: t("Name"), name: "name" },
        {
            label: t("Destinations"), name: "destinations",
            options: {
                customBodyRender: (value: any) => {
                    var list: String[] = [];
                    if (value && value.length) {
                        value.forEach((dest: any) => {
                            list.push(dest.name);
                        });
                    }
                    return list.toString();
                }
            },
        },
    ];

    const destcolumns = [
        { label: t("Name"), name: "name" },
        { label: t("Format"), name: "format" },
        { label: t("Endpoint"), name: "endpoint" },
        { label: t("Username"), name: "username" },
    ];

    const handleFormChange = (event: any) => {
        event.persist();
        var current = config;
        if (event.target.name === "path") {
            current![selConfigIndex].path = event.target.value;
        }
        if (event.target.name === "name") {
            current![selConfigIndex].name = event.target.value;
        }
        console.log(current);
        setConfig(current);
    };

    /*
    const { loading, data, error } = useAxios({
      url: `/api/vvr/configuration`,
      method: 'get',
    });
    */

    const data = [
        {
            "path": "config_3",
            "name": "Dev config",
            "clnt": 100,
            "destinations": [
                {
                    "name": "dest_1",
                    "format": "vdi",
                    "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                    "username": "vendsmartL",
                    "password": "sKDnZGDjX3772MWy"
                },
                {
                    "name": "dest_2",
                    "format": "vdi",
                    "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                    "username": "vendsmartL",
                    "password": "sKDnZGDjX3772MWy"
                },
                {
                    "name": "dest_3",
                    "format": "vdi",
                    "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                    "username": "vendsmartL",
                    "password": "sKDnZGDjX3772MWy"
                }
            ]
        },
        {
            "path": "config_1",
            "name": "Dev config",
            "clnt": 100,
            "destinations": [
                {
                    "name": "dest_1",
                    "format": "vdi",
                    "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                    "username": "vendsmartL",
                    "password": "sKDnZGDjX3772MWy"
                },
                {
                    "name": "dest_2",
                    "format": "vdi",
                    "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                    "username": "vendsmartL",
                    "password": "sKDnZGDjX3772MWy"
                }
            ]
        }
    ];

    useEffect(() => {
        console.log("in use effect");
        if (selConfigIndex == -1) {
            setConfig(data);
        }
    }, [config]);

    return (

        <>
            <ContentContainer py={4}>
                <MUIDataTable
                    title=""
                    data={config}
                    columns={confcolumns}
                    options={confoptions}
                />
            </ContentContainer>
            <ContentContainer py={4}>
                {config && selConfigIndex > -1 &&
                    <>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid item lg={2} md={2} xl={2} xs={2}>
                                <TField
                                    fullWidth
                                    label={t("Path")}
                                    name="path"
                                    value={config[selConfigIndex].path || ""}
                                    onChange={handleFormChange}
                                    ro={false}
                                />
                            </Grid>
                        </Grid>

                        <MUIDataTable
                            title=""
                            data={config[selConfigIndex].destinations}
                            columns={destcolumns}
                            options={confoptions}
                        />
                    </>
                }
            </ContentContainer>
        </>

    );



};



export default TelemetryConfiguration;

/*


[
    {
        "path": "config_3",
        "name": "Dev config",
        "clnt": 100,
        "destinations": [
            {
                "name": "dest_1",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            },
            {
                "name": "dest_2",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            },
            {
                "name": "dest_3",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            }
        ]
    },
    {
        "path": "config_1",
        "name": "Dev config",
        "clnt": 100,
        "destinations": [
            {
                "name": "dest_1",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            },
            {
                "name": "dest_2",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            }
        ]
    }
]



    <Form
      formik={formik}
      title={values.description}
      loadingSave={loadingSave}
    />
*/