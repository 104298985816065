import { useState } from "react";
import { Box, Breadcrumbs, Button, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useFormik } from "formik";
import { useSnackbar } from 'notistack';
import { ContentContainer, Confirm } from "components";
import { useCodes } from "hooks";
import validationSchema from "./validationSchema";
import Form from "./Form";
import Columns from "./Columns";
import Selections from "./Selections";
import Transactions from "./Transactions";
import { useTranslation } from "react-i18next";
import { axios } from "utilities";
import initialValues from "./initialValues";

type EquipmentsScreenProps = {
  data: any,
  handleSave: any,
  handleNavigate: any,
  loadingSave: boolean
}

const EquipmentsScreen = (props: EquipmentsScreenProps) => {
  const { data, handleSave, handleNavigate, loadingSave } = props;
  const [currentTab, setCurrentTab] = useState('detail');
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [modified, setModified] = useState(false);

  const { t } = useTranslation("equipment"); // Get translation
  const { enqueueSnackbar } = useSnackbar();
  const tabs = [
    { value: 'detail', label: t('Details') },
    { value: 'equimats', label: t('Materials') },
    { value: 'equisels', label: t('Selections') },
    { value: 'trans', label: t('Transactions') },
  ];

  const [selections, setSelections] = useState(data ? data.equipmentSels : []);
  const [columns, setColumns] = useState(data ? data.equipmentCols : []);
  const [materials, setMaterials] = useState(data ? data.materialList : []);

  const handleTabsChange = (event: any, value: string) => {
    setCurrentTab(value);
  };

  const equipment = data ? data.equipment : initialValues;

  // Init Formik
  const formik = useFormik({
    initialValues: equipment,
    validationSchema: validationSchema,
    enableReinitialize: true, // enableReinitialize = Allow to re-render after form data fetched
    onSubmit: values => { },
  });

  // Formik helpers
  const { handleReset, dirty, isValid, values, setFieldValue } = formik;

  const handleColumnsChange = (newColumns: any, matnr: string) => {
    setModified(true);
    var found = false;
    for (var i = 0; i < materials.length; i++) {
      if (materials[i].matnr === matnr) {
        found = true;
        break;
      }
    }
    if (found) {
      setColumns(newColumns);
    } else {
      axios
        .get('/api/material?matnr=' + matnr)
        .then((response) => {
          setMaterials((prevState: any) => [...prevState, response.data]);
          materials.push(response.data);
          setColumns(newColumns);
        });
    }
  }

  const handleSelectionsChange = (newSelections: any) => {
    setModified(true);
    setSelections(newSelections);
  }

  const handleEquipmentSave = () => {
    let equipmentAll = { equipment: values, equipmentCols: columns, equipmentSels: selections };
    handleSave(equipmentAll);
  }

  const handleEquipmentDelete = () => {
    setDeleteConfirm(true);
  }

  const handleEquipmentDeleteResult = (confirmed: boolean) => {
    if (confirmed) {
      let url = "/api/equipment?equnr=" + values.equnr;
      axios.delete(url)
        .then(response => {
          if (response.status === 200) {
            enqueueSnackbar("Equipment deleted successfully", { variant: "success" });
            handleNavigate();
          }
        })
        .catch(reason => {
          enqueueSnackbar("Error deleting equipment: " + reason, { variant: "error" });
        })
    }
    setDeleteConfirm(false);
  }

  // Fetch autocomplete codes
  const { codes, loadingCodes } = useCodes(["sorg", "equtype", "manufacturer", "campus", "telemetrypath"]);

  const dataModified = dirty || modified;

  return (
    <>
      {/* Fixed Header */}
      <Box sx={{
        py: 1.5,
        borderBottom: 1,
        borderBottomColor: "grey.300",
        backgroundColor: "grey.50",
      }}>
        <ContentContainer>
          <Grid container spacing={3} flexWrap="nowrap" alignItems="center">
            <Grid item xs>
              <Breadcrumbs />
              <Typography variant="h3">
                {values.equnr}
              </Typography>
            </Grid>
            <Grid item xs="auto">

              {/* Discard changes button */}
              {dirty && !loadingSave && !modified &&
                <Button
                  color="error"
                  startIcon={<CloseOutlinedIcon />}
                  sx={{
                    mr: 4
                  }}
                  onClick={handleReset}
                >
                  Discard Changes
                </Button>
              }

              {/* Save button */}
              <LoadingButton
                variant="contained"
                startIcon={<SaveOutlinedIcon />}
                disabled={!dataModified || !isValid || loadingSave || loadingCodes}
                loading={loadingSave}
                onClick={handleEquipmentSave}>
                Save
              </LoadingButton>

              {/* Delete button */}
              {values.guid &&
                <Button
                  color="error"
                  startIcon={<DeleteOutlinedIcon />}
                  sx={{
                    ml: 4
                  }}
                  onClick={handleEquipmentDelete}
                >
                  Delete Equipment
                </Button>
              }
            </Grid>
          </Grid>
        </ContentContainer>
      </Box>
      <ContentContainer>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      </ContentContainer>
      <Divider />
      {currentTab === 'detail' &&
        <Form
          formik={formik}
          codes={codes}
          loadingCodes={loadingCodes}
          loadingSave={loadingSave}
        />}
      {currentTab === 'equimats' &&
        <Columns
          columns={columns}
          materials={materials}
          handleChange={handleColumnsChange}
        />}
      {currentTab === 'equisels' &&
        <Selections
          equnr={values.equnr}
          selections={selections}
          handleChange={handleSelectionsChange}
        />}
      {currentTab === 'trans' &&
        <Transactions
          equnr={values.equnr}
        />}

      <Confirm
        show={deleteConfirm}
        message={"Delete Equipment " + values.equnr + "?"}
        handleResult={handleEquipmentDeleteResult}
      />

    </>
  );
};

export default EquipmentsScreen;