import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { Card, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAxios } from "hooks";
import { ContentContainer } from "components";
import TelemetrySearchForm from "./components/TelemetrySearchForm";
import initialValues from "./components/initialValues";
import { useSelector } from "react-redux";

const TelemetryMonitor = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("telemetry");

  // TODO: Change type any after redo auth
  const { user: { client: { clnt } } } = useSelector((state: any) => state.user);

  // Define search form handler
  const [post, { data: searchResult, loading: loadingSave }] = useAxios({
    method: "post",
    url: "/api/vvr/monitor",
  });

  // Init Formik
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: values => {
      post(values); // Call search
    },
    onReset: values => {

    }
  });

  const { values } = formik;

  return (
    <ContentContainer>
      <TelemetrySearchForm
        formik={formik}
        loadingSave={loadingSave}
      />
    </ContentContainer>

  );
};



export default TelemetryMonitor;

/*
[
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/9c1c88bf-73ad-422f-ab3e-5f9165f6b4cc.xml",
        "receivedAt": "1721569146482",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:06.334Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:08.222432092Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:08.540242672Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:08.540580822Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/ad447a3e-6d72-4b22-bdb5-b02fb2b4510c.xml",
        "receivedAt": "1721569146130",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:05.973Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:07.324261323Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:07.646744912Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:07.647059662Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/52927ff0-cc96-418b-905d-2ab1bc813939.xml",
        "receivedAt": "1721569145670",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:05.511Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:07.297723454Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:07.596353671Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:07.596678494Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/338906b4-5149-467a-9e1d-f46b5a715477.xml",
        "receivedAt": "1721569145326",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:05.178Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:06.372526683Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:06.802850003Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:06.803198733Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/09abf7e4-209c-4144-a5fd-28b793d2f1d0.xml",
        "receivedAt": "1721569144951",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:04.762Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:06.387811382Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:06.837899713Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:06.838216239Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/66d3bc2b-008c-4cbc-8a5e-60f6fde19bd2.xml",
        "receivedAt": "1721569144566",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:04.235Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:06.223909564Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:06.569200761Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:06.569616717Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/bdc9fcf7-7767-4a06-b59e-47cb84aacb68.xml",
        "receivedAt": "1721569143881",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:03.554Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:05.110536837Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:05.407393378Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:05.407696648Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/214f7d10-f73e-48a4-ae27-515dc706c5d5.xml",
        "receivedAt": "1721569143362",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:03.202Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:05.169610657Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:05.483939487Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:05.484364057Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/6ea2c7c9-1560-4bea-a696-0f095d31d4cf.xml",
        "receivedAt": "1721569142930",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:02.775Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:04.098841948Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:04.425802928Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:04.426145488Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/c4d03416-7307-4e27-a25d-4f4695ea5223.xml",
        "receivedAt": "1721569142574",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:02.416Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:03.770211973Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:04.134082743Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:04.134488137Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/f19d0ef5-f7ef-42be-9cdc-8053ae24fc08.xml",
        "receivedAt": "1721569142174",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:02.001Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:03.698986230Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:03.996704930Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:03.997048427Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/b45b3f81-eda9-4f29-aaf8-223dc6a9764a.xml",
        "receivedAt": "1721569141793",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:01.645Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:02.801492211Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:03.150400850Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:03.150743790Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/991f9877-f37f-4667-949e-7d29b9273eaf.xml",
        "receivedAt": "1721569141446",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:01.301Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:02.732242468Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:03.144844340Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:03.145240379Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/50412649-1cfe-4a47-9c0f-a7e834f434b8.xml",
        "receivedAt": "1721569141019",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:00.873Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:02.732850888Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:03.061871118Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:03.062224708Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/15154976-fee4-43cc-b2df-98cae791916a.xml",
        "receivedAt": "1721569140692",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:00.545Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:01.845225028Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:02.227430369Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:02.227739138Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/998302b4-1b06-4429-8a61-e48ac57ba152.xml",
        "receivedAt": "1721569140354",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:39:00.211Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:01.798675178Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:02.100748445Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:02.101063385Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/9ccf1f01-0a6d-48cd-aeb4-e1d2397afbd1.xml",
        "receivedAt": "1721569139964",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:59.634Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:01.791422734Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:02.225554780Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:02.225973374Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/12187cab-c7cb-4d3c-9647-a5f873ac1a6a.xml",
        "receivedAt": "1721569139415",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:59.271Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:00.811646400Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:01.243519288Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:01.243926541Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/2d47ea08-17e0-416e-883b-84ef3ecafc92.xml",
        "receivedAt": "1721569138997",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:58.672Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:00.830230047Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:01.231128708Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:01.231458180Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/65cd3578-11f5-49ae-acde-47354b9d397e.xml",
        "receivedAt": "1721569138394",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:58.053Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:59.797472331Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:39:00.124532958Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:39:00.124920951Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/9378f334-43ea-4e3c-8521-bd50f4ff64a2.xml",
        "receivedAt": "1721569137815",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:57.669Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:58.921371925Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:59.312937959Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:59.313256912Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/b17db034-54ce-429b-89ff-45a0002d7252.xml",
        "receivedAt": "1721569137471",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:57.319Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:58.972626662Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:59.355957387Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:59.356389209Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/d8414b94-bda9-4396-a67c-ce8a5aa619b6.xml",
        "receivedAt": "1721569136975",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:56.829Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:58.807938289Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:59.152621699Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:59.152971029Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/b252ae35-8bf5-4593-8c9b-ce0e680a187e.xml",
        "receivedAt": "1721569136638",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:56.490Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:57.509751695Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:57.795152225Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:57.795452251Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/d8c915d1-587a-4a4b-bccd-a11353d5e18f.xml",
        "receivedAt": "1721569136213",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:55.881Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:57.589904259Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:57.901584499Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:57.901884189Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/19d90786-e36d-41c7-9bcb-a0d3adb93335.xml",
        "receivedAt": "1721569135685",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:55.535Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:56.752625805Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:57.077552488Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:57.077882651Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/6d38fa4a-5323-4faa-ab91-15abe23d041f.xml",
        "receivedAt": "1721569135298",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:55.130Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:56.596284819Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:56.925801778Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:56.926291926Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/7362a66a-56a7-476b-87a6-5ce0ff818291.xml",
        "receivedAt": "1721569134910",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:54.763Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:56.671372259Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:56.999164749Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:56.999496669Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/c3119f33-b543-4844-8913-a732f7fc0e05.xml",
        "receivedAt": "1721569134573",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:54.422Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:55.933828964Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:56.273736510Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:56.274135404Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/aa311a3b-6a04-4fd3-bef0-9e5f4711bb90.xml",
        "receivedAt": "1721569134142",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:53.991Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:55.789433119Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:56.146746457Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:56.147084701Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/2a135eed-4588-41ae-a8b4-ad86df04e3c7.xml",
        "receivedAt": "1721569133807",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:53.475Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:55.781942906Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:56.176295359Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:56.176608739Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/4a88300f-7974-454d-ba49-e966d86e72d9.xml",
        "receivedAt": "1721569133236",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:52.909Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:54.628909465Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:54.934867365Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:54.935196589Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/6cd65ad3-9c65-4e97-bbaa-6f7f9bf4b3cb.xml",
        "receivedAt": "1721569132725",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:52.578Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:53.787793190Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:54.137084562Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:54.137418673Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/cc607f36-e812-4e82-a788-723df6f70ed7.xml",
        "receivedAt": "1721569132373",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:52.215Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:53.557267930Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:53.855384460Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:53.855665860Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/4dc932ab-70c6-4601-9ed3-4f84594bb4cc.xml",
        "receivedAt": "1721569131935",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:51.609Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:53.235990836Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:53.731419319Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:53.731838545Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/52321cb0-b4ba-4c4a-aca8-faa06cc7b729.xml",
        "receivedAt": "1721569131419",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:51.265Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:53.148712596Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:53.479209964Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:53.479570375Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/d0b6f1f6-a7aa-4bd6-bb2b-17552312d626.xml",
        "receivedAt": "1721569130987",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:50.840Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:52.292336032Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:52.732448950Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:52.732891287Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/87f21ff9-4197-4110-80af-8f5bbc479504.xml",
        "receivedAt": "1721569130651",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:50.500Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:52.215918740Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:52.596287410Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:52.596570180Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/75a7741c-3d6e-4339-9f70-cbbd410defee.xml",
        "receivedAt": "1721569130302",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:50.151Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:52.373066663Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:52.781379117Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:52.781766578Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/5b80186d-90c9-4511-9a31-16462bd2e3ec.xml",
        "receivedAt": "1721569129861",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:49.531Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:51.200370950Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:51.500802930Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:51.501187060Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/820114bd-f1f2-4604-a596-b25931cdc355.xml",
        "receivedAt": "1721569129330",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:49.175Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:51.092979979Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:51.416210337Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:51.416649495Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/c46bedb2-f539-45dd-969e-88b3d3869ec0.xml",
        "receivedAt": "1721569128898",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:48.749Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:50.096704490Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:50.402819170Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:50.403104420Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/e0f6a6e0-8859-4d18-8aaf-c041ab5f0bd7.xml",
        "receivedAt": "1721569128555",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:48.394Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:50.234536487Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:50.533355407Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:50.533760649Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/5e1ad2af-9eaf-44b3-b460-8412b21168fa.xml",
        "receivedAt": "1721569128153",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:47.962Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:49.234989280Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:49.559378820Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:49.559660440Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/8bd5238c-da1e-4376-8f2f-19569022da91.xml",
        "receivedAt": "1721569127776",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:47.627Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:49.058206665Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:49.396221055Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:49.396563979Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/8b60e1c1-80a0-4405-855c-99d3989e5feb.xml",
        "receivedAt": "1721569127395",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:47.239Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:49.174804719Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:49.496163365Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:49.496555869Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/135994c2-d59a-4dc7-af0b-798a9b91a932.xml",
        "receivedAt": "1721569126958",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:46.807Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:48.161854198Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:48.537666105Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:48.538030911Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/8abde6fa-e987-478d-9e69-c3083aa89d90.xml",
        "receivedAt": "1721569126625",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:46.293Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:48.164367845Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:48.521165665Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:48.521554759Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/bf71a1cc-d628-4a75-b310-7acf7da4fc5b.xml",
        "receivedAt": "1721569126032",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:45.694Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:47.075242396Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:47.446210731Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:47.446567466Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    },
    {
        "deviceId": "SLW4-000017",
        "s3Key": "raw/year=2024/month=7/day=21/a9a38be4-1458-4b54-b18f-81858fdf5a91.xml",
        "receivedAt": "1721569125472",
        "isValidFile": null,
        "processingStatus": null,
        "indexInFile": 0,
        "soapMetadata": {
            "customerId": "united",
            "providerId": "SLW",
            "applicationId": "VMReader",
            "applicationVersion": "001",
            "dexEncoding": 2,
            "dexCompressionType": "",
            "dexCompressionParam": "",
            "vdiXmlVersion": "1.0"
        },
        "dexMetadata": {
            "readDateTime": "2024-07-21T13:38:45.322Z",
            "gmtOffset": 0,
            "dexReason": 6,
            "dexType": 0,
            "responseCode": "OK",
            "fileSize": 5056,
            "transactionTime": "Tue Jun 25 19:13:53 UTC 2024",
            "transactionId": "SLW1719342833822"
        },
        "destinationDelivery": [
            {
                "configurationPath": "config_1",
                "destinationName": "dest_1",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:47.119787721Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            },
            {
                "configurationPath": "config_1",
                "destinationName": "dest_2",
                "deliveryStatus": "DELIVERED",
                "deliveryFailureReason": null,
                "deliveryDateTime": "2024-07-21T13:38:47.542514025Z",
                "lastFailureAt": null,
                "responseStatusCode": 200
            }
        ],
        "lastUpdatedAt": "2024-07-21T13:38:47.542939348Z",
        "version": 3,
        "deliveryStatus": "DELIVERED"
    }
]
*/