import React from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { Page, Copyright } from "components";

const PREFIX = 'Index';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.card}`]: {
    maxWidth: 682,
    marginBottom: 30,
    marginRight: 'auto',
    marginLeft: 'auto',
  }
}));

function Index() {

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <StyledPage className={classes.root} title="Suelware Payment Gateway">
      <Container>
        <Card className={classes.card}>
          <CardMedia
            component="img"
            image="/img/coke-can.jpg"
            height="500"
            title=""
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h3"
              component="h1"
              align="center"
            >
              Suelware Payment Gateway
            </Typography>
            <Typography
              gutterBottom
              variant="h4"
              component="h1"
              align="center"
            >
              Version 1.9.1
            </Typography>
            <Copyright />
          </CardContent>
          <CardActions>
            <Button size="large" color="primary" variant="contained" onClick={handleLoginClick} fullWidth>
              Login
            </Button>
          </CardActions>
        </Card>
      </Container>
    </StyledPage>
  );
}

export default Index;
