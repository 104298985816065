import { useState } from "react";
import { Grid } from "@mui/material";
import { ContentContainer, FormSubsection, FormikChip, DisplayField, FormikTextField, FormikAutocomplete } from "components";
import { FormikContextType, FormikProvider } from "formik";
import { useTranslation } from "react-i18next";
import { selOptions } from "utilities";
import AssignTLC from "./AssignTLC";
import ConfigTLC from "./ConfigTLC";

type Props = {
  formik: FormikContextType<any>;
  codes: any;
  loadingCodes: boolean;
  loadingSave: boolean;
};

const EquipmentDetailForm = (props: Props) => {
  const { formik, codes, loadingCodes } = props;
  const { handleSubmit, values, setFieldValue } = formik;
  const { t } = useTranslation("equipment"); // Get tranlation
  const [assignOpen, setAssignOpen] = useState(false);
  const [configOpen, setConfigOpen] = useState(false);

  const handleAssignChange = (identifier: string, identity: string, macaddress: string, apply: boolean) => {
    if (apply) {
      setFieldValue("tlcserialnr", identifier);
      setFieldValue("tlcidentity", identity);
      setFieldValue("macaddress", macaddress);
    }
    setAssignOpen(false);
  }

  const handleConfigChange = (config: string, dexoffset: string, apply: boolean) => {
    if (apply) {
      setFieldValue("tlcconfig", config);
      setFieldValue("dexoffset", dexoffset);
    }
    setConfigOpen(false);
  }

  return (
    <>
      <FormikProvider value={formik}>
        <form className="h-100" onSubmit={handleSubmit}>
          <ContentContainer py={4}>

            {/* Main column to limit width */}
            <Grid container spacing={3}>
              <Grid item>

                <FormSubsection title={t("Active")}>
                  <FormikChip
                    name="active"
                    labelActive={t("Active")}
                    labelInactive={t("Inactive")}
                  />
                </FormSubsection>

                <FormSubsection title={t("General Data")}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="equnr"
                        label={t("Equipment Number")}
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="macaddress"
                        label={t("MAC address")}
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikAutocomplete
                        name="equtype"
                        options={selOptions(codes, "equtype")}
                        label={t("Type")}
                        loading={loadingCodes}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikAutocomplete
                        name="sorg"
                        options={selOptions(codes, "sorg")}
                        label={t("Sales Org")}
                        loading={loadingCodes}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormikTextField
                        type="text"
                        name="profitcenter"
                        label={t("Profit Center")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormikAutocomplete
                        name="campus"
                        options={selOptions(codes, "campus")}
                        label={t("Campus")}
                        loading={loadingCodes}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="terminalnr"
                        label={t("Terminal")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="outlet"
                        label={t("Outlet")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="outletname"
                        label={t("Outlet Name")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="outletaddress"
                        label={t("Outlet Address")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="label"
                        label={t("Label")}
                      />
                    </Grid>
                  </Grid>
                </FormSubsection>

                <FormSubsection title={t("Payment Mode")}>
                  <FormikChip
                    name="demomode"
                    labelActive={t("DEMO MODE - FREE VENDS")}
                    labelInactive={t("Normal operation")}
                  />
                </FormSubsection>

                <FormSubsection title={t("Classification")}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="manufacturer"
                        label={t("Manufacturer")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="model"
                        label={t("Model")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="factoryserialnr"
                        label={t("Factory Serial")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        type="text"
                        name="serialnr"
                        label={t("GPU Serial")}
                      />
                    </Grid>
                  </Grid>
                </FormSubsection>

                <FormSubsection title={t("Telemetry")}>
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <FormikTextField
                        type="text"
                        name="paymenttermserialnr"
                        label={t("Payment Term. Serial")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormikTextField
                        type="text"
                        name="telemetryid"
                        label={t("Telemetry ID")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormikAutocomplete
                        name="telemetrypath"
                        options={selOptions(codes, "telemetrypath")}
                        label={t("Telemetry Routing Path")}
                        loading={loadingCodes}
                      />
                    </Grid>
                  </Grid>
                </FormSubsection>

                <FormSubsection title={t("Note")}>
                  <FormikTextField
                    type="text"
                    name="note"
                    label={t("Note")}
                    multiline
                    maxRows={4}
                  />
                </FormSubsection>

                <FormSubsection title={t("Status")}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <DisplayField
                        data={values}
                        name="lasttransaction"
                        label={t("Last Transaction")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <DisplayField
                        data={values}
                        name="lasterror"
                        label={t("Last Result/Error")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DisplayField
                        data={values}
                        name="lastupdateat"
                        label={t("Last Update At")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <DisplayField
                        data={values}
                        name="lastupdateby"
                        label={t("Last Update By")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DisplayField
                        data={values}
                        name="lastconfigupdateat"
                        label={t("Last Config Update At")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <DisplayField
                        data={values}
                        name="lastconfigupdateby"
                        label={t("Last Config Update By")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DisplayField
                        data={values}
                        name="lastdexupdateat"
                        label={t("Last DEX Update At")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <DisplayField
                        data={values}
                        name="lastdexupdateby"
                        label={t("Last DEX Update By")}
                      />
                    </Grid>
                  </Grid>
                </FormSubsection>

                {values.equtype === "VM" &&
                  <>
                    <FormSubsection title={t("TLC / TVM")}>
                      <Grid container spacing={3}>
                        <Grid item xs={2}>
                          <DisplayField
                            data={values}
                            name="tlcserialnr"
                            label={t("Serial")}
                            onClick={() => setAssignOpen(true)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DisplayField
                            data={values}
                            name="tlcidentity"
                            label={t("Identity")}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <DisplayField
                            data={values}
                            name="tlcconfig"
                            label={t("Config")}
                            onClick={() => setConfigOpen(true)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <DisplayField
                            data={values}
                            name="dexoffset"
                            label={t("DEX Offset")}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <DisplayField
                            data={values}
                            name="tlcfirmware"
                            label={t("Firmware")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DisplayField
                            data={values}
                            name="tlcinfo"
                            label={t("Info")}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <DisplayField
                            data={values}
                            name="meter"
                            label={t("Meter")}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <DisplayField
                            data={values}
                            name="totalizer"
                            label={t("Totalizer")}
                          />
                        </Grid>
                      </Grid>
                    </FormSubsection>

                    <FormSubsection title={t("Inventory tracking")}>
                      <FormikChip
                        name="tlcoos"
                        labelActive={t("Detect OOS")}
                        labelInactive={t("Ignore OOS")}
                      />
                    </FormSubsection>
                  </>
                }

              </Grid>
            </Grid>

          </ContentContainer>
        </form>
      </FormikProvider>

      <ConfigTLC
        show={configOpen}
        values={values}
        handleChange={handleConfigChange}
      />

      <AssignTLC
        show={assignOpen}
        handleChange={handleAssignChange}
      />
    </>
  );
};

export default EquipmentDetailForm;
